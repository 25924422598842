import { Box, Skeleton } from "components";
import ContentMgtLayout from "layouts/contentMgt";
import React, { useState } from "react";
import Details from "./components/details";
import { IHttpError } from "interfaces/httpError";

import OtherTransactions from "./components/otherTransactions";
import { useParams } from "react-router-dom";
import {
  useArchiveTicket,
  useUpdateTransaction,
} from "hooks/queries/transactions";
import { TransactionDataI } from "types/client";
import { useAuth } from "contexts/AuthContext";
// import moment from "moment";
// import { useGetUserWalletBalance } from "hooks/queries/users";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { POWERFULL_MERCHANT_BASE_URL } from "constants/config";
import { MerchatTransaction } from "interfaces/shareable";
import { getAccessToken } from "utils/localStorage";
import UpdateWallet from "./components/updateWallet/UpdateWallet";
import { EmailBroadcast, SmsBroadcast } from "interfaces/broadcast";

type EmailBroadcastPayload = EmailBroadcast<
  { firstName: string; lastName: string }[]
>;
type SmsBroadcastPayload = SmsBroadcast<
  { firstName: string; lastName: string }[]
>;

const MerchantPayin = () => {
  const [selectedCondition] = useState("");
  const params = useParams();

  const { isLoading, data } = useQuery(
    ["merchant-transaction", params.id],
    async () => {
      const { data } = await axios.get<{ transaction: MerchatTransaction }>(
        `${POWERFULL_MERCHANT_BASE_URL}transactions/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()} `,
          },
        }
      );
      return data;
    }
  );

  const { mutate, isLoading: updateConditionLoading } = useUpdateTransaction(
    params?.id || "",
    selectedCondition
  );

  const { isLoading: broadcasting, mutate: broadcast } = useMutation(
    async (cred: EmailBroadcastPayload) =>
      await axios.post<{ broadcast: EmailBroadcastPayload }>(
        `${POWERFULL_MERCHANT_BASE_URL}email-broadcast`,
        cred,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()} `,
          },
        }
      ),
    {
      onSuccess() {
        toast.success("Broadcast sent");
      },
      onError(error: IHttpError) {
        toast.error(error.response.data.message);
      },
    }
  );

  const { isLoading: smsBroadcasting, mutate: smsBroadcast } = useMutation(
    async (cred: SmsBroadcastPayload) =>
      axios.post<{ broadcast: EmailBroadcastPayload }>(
        `${POWERFULL_MERCHANT_BASE_URL}sms-broadcast`,
        cred,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()} `,
          },
        }
      ),
    {
      onSuccess() {
        toast.success("Broadcast sent");
      },
      onError(error: IHttpError) {
        toast.error(error.response.data.message);
      },
    }
  );

  const handleUpdateCondition = () => {
    if (!selectedCondition) {
      toast.error("Kindly select a condition");
    } else {
      mutate();
    }
  };

  const handleBroadcast = (val: any) => {
    if (!val.message) {
      toast.error("Enter broadcast message");
      return;
    }
    const user = data?.transaction.user;
    if (val.channels.includes("1")) {
      const emails = [user?.email];
      const datax = [
        {
          firstName: user?.name!,
          lastName: "",
        },
      ];
      const payload: EmailBroadcastPayload = {
        subject: val.subject,
        message: val.message,
        emails: emails.toString(),
        data: datax,
      };
      broadcast(payload);
    }

    if (val.channels.includes("3")) {
      const phoneNumbers = [user?.mobile!];
      const datax = [
        {
          firstName: user?.name!,
          lastName: "",
        },
      ];

      const payload: SmsBroadcastPayload = {
        channel: val.smsChannel,
        sms: val.message,
        to: phoneNumbers,
        data: datax,
      };

      smsBroadcast(payload);
    }
  };

  return (
    <ContentMgtLayout pageTitle="Transaction" header="Transaction">
      <Box className="singleNotification">
        {isLoading && (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
        {!isLoading && (
          <>
            <Details
              //@ts-ignore
              transaction={data?.transaction}
              walletBalance={0}
              walletBalanceLoading={false}
              condition="Started"
              selectedCondition={selectedCondition}
              handleUpdateCondition={handleUpdateCondition}
              handleSubmit={handleBroadcast}
              messageLoading={broadcasting || smsBroadcasting}
            />
            <UpdateWallet
              transaction={data?.transaction!}
              //@ts-ignore
              userId={data?.transaction?.user?._id}
            />

            <OtherTransactions
              //@ts-ignore
              user={data?.transaction?.user._id}
            />
          </>
        )}
      </Box>
    </ContentMgtLayout>
  );
};

export default MerchantPayin;
